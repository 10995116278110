@import '~antd/dist/antd.css';

/*CORE*/
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.messagesError {
  margin-top: 10px;
}

.messagesError div {
  background-color: #EF9A9A;
  color: #ffffff;
  padding-right: 40px;
}

.messagesError ul {
  list-style: none;
}

.headerContents{
  margin-top: -5px;
}

.ant-form-item{
  margin-bottom: 10px;
}

div.ant-form-item-control .line-height-fix {
  line-height: 29.9999px
}